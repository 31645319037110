@media only screen and (min-width: 320px) and (max-width: 992px) {
  body,html {
    overflow-x: clip !important;
  }
  .MuiPagination-root.MuiPagination-text {
    position: relative !important;
    opacity: 1 !important;
    right: 0px !important;
  }
}
