.product-compare-container {
    display: flex;
    align-items: flex-start;
  }
  
  .fixed-header {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 2;
  }
  
  .fixed-header table {
    width: auto;
  }
  
  .scrollable-content {
    overflow-x: auto;
    width: 100%;
  }
  
  .scrollable-content .slick-slide {
    display: inline-block;
    width: 300px; /* Adjust the width as needed */
  }
  
  .scrollable-content .item table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th, .table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
    white-space: nowrap;
  }
.homeProductsRow2 .prodSlider .item.table-wrapper{
    padding: 0px 5px;
}
  