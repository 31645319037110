.nav {
  width: 100%;
  height: 80px;
  margin: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.nav .catTab {
  padding: 8px 20px !important;
  font-size: 20px !important;
  text-transform: capitalize !important;
}

.nav nav ul li {
  position: relative;
}
.nav nav ul li button {
  text-transform: capitalize !important;
  padding: 21px 25px !important;
  color: #3bb77e !important;
  border-radius: 0px !important;
}
.nav nav ul li button a {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 21px;
}

.nav .part3 .phNo svg {
  font-size: 45px !important;
  opacity: 0.8;
}
.nav .part3 .phNo h3 {
  font-size: 32px !important;
  line-height: 35px !important;
}
.nav .part3 .phNo p {
  font-size: 16px !important;
  opacity: 0.8;
}

.nav nav .dropdown_menu {
  position: absolute;
  top: 150%;
  left: 0px;
  width: 300px;
  height: auto;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 10px 0px;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s ease-in-out;
}
nav .dropdown_menu li {
  width: 100% !important;
  list-style: none;
  margin: 0px !important;
}
.nav nav .dropdown_menu li button {
  width: 100% !important;
  text-align: left !important;
  justify-content: flex-start !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  padding: 10px 20px !important;
  color: #3bb77e !important;
}

.nav nav .dropdown_menu li button a {
  font-weight: 400 !important;
}

nav .dropdown_menu li button:hover {
  background: #f1f1f1 !important;
}

.nav nav li:hover .dropdown_menu {
  top: 100%;
  opacity: 1;
  visibility: inherit;
}

.megaMenu {
  height: auto !important;
  padding: 40px 45px !important;
}

.megaMenu .col ul li {
  margin-bottom: 10px !important;
  display: block;
}
.megaMenu .col ul li a {
  color: rgba(0, 0, 0, 0.7);
  font-size: 20px;
  text-decoration: none;
}
.position-relative {
  position: relative;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.bg-g {
  background-color: #2c3e50;
}

.text-white {
  color: white;
}

.catTab {
  cursor: pointer;
}

.res-hide {
  display: none;
}

/* Experimental */
.scroll-container {
  /* overflow-x: auto; */
  white-space: nowrap;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS devices */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers */
}

.scroll-container {
  -ms-overflow-style: none;  /* Hide scrollbar in IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar in Firefox */
}
.cursor-pointer{
  cursor: pointer !important;
}
/* Experimental */

@media (min-width: 576px) {
  .res-hide {
    display: block;
  }
}

.position-absolute {
  position: absolute;
}

.dropdown-wrapper {
  top: 100%; /* Position dropdown below the button */
  left: 0;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  padding: 10px 0;
  min-width: 300px; 
  max-height: 378px;
  overflow-y: auto;
}

.dropdown-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-wrapper li {
  padding: 10px 20px;
  text-align: left !important;
}

.dropdown-wrapper li:hover {
  background-color: #f0f0f0;
}

.dropdown-wrapper a {
  text-decoration: none;
  color: black;
  display: block;
}

.dropdown-wrapper a:hover {
  color: rgb(253, 169, 45);
}
.backdrop.show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 9999; /* Adjust the z-index to ensure it overlays other content */
}
