@media only screen and (min-width: 320px) and (max-width: 992px) {
  body,html {
    overflow-x: clip !important;
  }
  #wishlist-view nav {
    display: contents !important;
  }
  #wishlist-view nav ul {
    display: contents !important;
  }
  #wishlist-view nav ul li button {
    width: 10px !important;
  }
}
